<template>
  <div id="xinwen" class="Fantasy">
    <div class="FantasyBody">
      <div class="Fantasy-warp">
        <h2 class="header">
          <img src="https://thcdn.gggamedownload.com/source/assets/T-1.png">
        </h2>
        <div class="warp-body">
          <div class="body-left">
            <div class="top">
              <swiper ref="mySwiper" :options="swiperOption">
                <swiper-slide v-for="item in carouselList" :key="item.id">
                  <img :src="item.img_path" alt="" @click="handleGoto(item)">
                </swiper-slide>
              </swiper>
              <div slot="pagination" class="swiper-pagination" />
            </div>
            <div class="bottom" @click="gotoPath(PopularData)">
              <div class="date">
                <p class="max">{{ PopularData.createTime | fileter_monteh }}</p>
                <p style="color: #788fa1">{{ PopularData.createTime | fileter_year }}</p>
              </div>
              <div class="news">
                <h5>
                  <span class="titleText">{{ PopularData.tag | fileter_type }}</span>
                  {{ PopularData.title }}
                </h5>
                <p class="Text">
                  {{ PopularData.description }}
                </p>
              </div>
            </div>
          </div>
          <div class="body-right">
            <div class="head">
              <ul class="left">
                <li
                  v-for="item in ListTitle"
                  :key="item.id"
                  :class="{ active: item.id === activeId }"
                  @click="handleChangeType(item)"
                >
                  {{ item.title }}
                </li>
              </ul>
              <span class="right"> <router-link style="color:#4a5f6d;" to="/newsLIst">MORE+</router-link> </span>
            </div>
            <div class="bot">
              <ul>
                <li v-for="item in newNewsList" :key="item.id" @click="gotoPath(item)">
                  <div class="left">
                    <div class="date">{{ item.createTime | fileter_monteh }}</div>
                    <div class="year">{{ item.createTime | fileter_year }}</div>
                  </div>
                  <div class="right">
                    <h5>
                      <span class="titleText">{{ item.tag | fileter_type }}</span>
                      {{ item.title }}

                    </h5>
                    <p class="Text">
                      {{ item.description }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import "swiper/css/swiper.css"

const Carousel1 = 'https://thcdn.gggamedownload.com/source/assets/5-1.jpg'
const Carousel2 = 'https://thcdn.gggamedownload.com/source/assets/shiting.jpg'
const Carousel3 = 'https://thcdn.gggamedownload.com/source/assets/Z.jpg'
const Carousel4 = require('@/assets/swiper/1.jpg')
import { reqGetNewsList } from '@/api/news'
import { __getSwiperList } from '@/api/imgInfo'

export default {
  name: 'Fantasy',
  components: {
    Swiper,
    SwiperSlide
  },
  filters: {
    fileter_monteh(val) {
      if (val) {
        const str = val.substring(0, 10)
        const index = str.indexOf('-')
        const newVal = str.substring(index + 1)
        return newVal
      }
      return val
    },
    fileter_year(val) {
      if (val) {
        const str = val.substring(0, 10)
        const index = str.indexOf('-')
        const newVal = str.substring(0, index)
        return newVal
      }
      return val
    },
    fileter_type(val) {
      switch (val) {
        case 'all':
          return '最新'
        case 'news':
          return '新闻'
        case 'announcement':
          return '公告'
        case 'gameWalkthrough':
          return '活动'
        case 'gameStrategy':
          return '见闻纪'
        default:
          return val
      }
    }
  },
  data() {
    return {
      swiperOption: {
        // API
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 4000
        }
      },
      ListTitle: [
        {
          id: 1,
          title: '最新',
          type: null
        },
        {
          id: 2,
          title: '新闻',
          type: 'news'
        },
        {
          id: 3,
          title: '公告',
          type: 'announcement'
        },
        {
          id: 4,
          title: '活动',
          type: 'gameWalkthrough'
        },
        {
          id: 5,
          title: '见闻纪',
          type: 'gameStrategy'
        }
      ],
      newsList: [
        {
          id: 1,
          title: '新闻',
          date: '07-15',
          year: '2021',
          h: '【东方归言录】首次删档不计费测试定档6月17日',
          content: '各位外乡人们久等了!二次创作回合制RPG手游《东方归言录》终于要和大家见面了！6月17日10:00分将开启测试！《东方归言录》是一款以“东方PROJECT”'
        },
        {
          id: 2,
          title: '公告',
          date: '07-15',
          year: '2021',
          h: '【东方归言录】首次删档不计费测试定档6月17日',
          content: '各位外乡人们久等了!二次创作回合制RPG手游《东方归言录》终于要和大家见面了！6月17日10:00分将开启测试！《东方归言录》是一款以“东方PROJECT”'
        },
        {
          id: 3,
          title: '活动',
          date: '07-15',
          year: '2021',
          h: '【东方归言录】首次删档不计费测试定档6月17日',
          content: '各位外乡人们久等了!二次创作回合制RPG手游《东方归言录》终于要和大家见面了！6月17日10:00分将开启测试！《东方归言录》是一款以“东方PROJECT”'
        },
        {
          id: 4,
          title: '新闻',
          date: '07-15',
          year: '2021',
          h: '【东方归言录】首次删档不计费测试定档6月17日',
          content: '各位外乡人们久等了!二次创作回合制RPG手游《东方归言录》终于要和大家见面了！6月17日10:00分将开启测试！《东方归言录》是一款以“东方PROJECT”'
        }

      ],
      newNewsList: [],
      carouselList: [],
      activeId: 1,
      queryForm: {
        channel: 'th.gg.com',
        pageSize: 5,
        currentPage: 1,
        tag: ''
      },
      PopularData: {}
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  created() {
    this.getList()
    this.getCarouselList()
  },
  methods: {
    async getCarouselList() {
      const res = await __getSwiperList()
      if (res.code === 200) {
        this.carouselList = res.data
      }
    },
    async getList() {
      const res = await reqGetNewsList(this.queryForm)
      this.PopularData = res.data[0] || {}
      if (res.data.length >= 5) {
        res.data = res.data.splice(1, 4)
      }
      this.newsList = res.data
      this.newNewsList = this.newsList
    },
    handleChangeType({ id, type }) {
      this.activeId = id
      this.queryForm.tag = type
      this.getList()
    },
    gotoPath({ id }) {
      this.$router.push({
        path: '/NewsDetails',
        query: {
          id
        }
      })
    },
    handleGoto(item) {
      if (item.img_to_path) {
        window.open(item.img_to_path)
      }
    }
  }

}
</script>
 <style lang='scss' scoped>
::v-deep {
  .swiper-pagination-bullet {
    display: inline-block;
    margin: 0 10px;
    width: 12px;
    height: 12px;
    border-radius: 0;
    opacity: 1;
    transform: rotateZ(45deg);
    background-color: #4a5f6d !important;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    width: 14px;
    height: 14px;
    background-color: #acbfcd !important;
  }
}

.Fantasy {
  width: 100%;
  height: 950px;
  background: url("https://thcdn.gggamedownload.com/source/assets/zhuyeBG/BG-2.jpg") no-repeat center;
  background-size: 100%;
  background-color: #d9d4d3;
  .FantasyBody {
    width: 100%;
    .Fantasy-warp {
      width: 71%;
      margin: 0 auto;
      .header {
        height: 240px;
        overflow: hidden;
        width: 71%;
        display: flex;
        align-items: center;
        img {
          width: 364px;
        }
        // background: url("https://thcdn.gggamedownload.com/source/assets/T-1.png") no-repeat center left;
      }
      .warp-body {
        display: flex;
        width: 100%;
        .body-left,
        .body-right {
          width: 50%;
        }
        .body-left {
          padding-top: 47px;
          .top {
            cursor: pointer;
            width: 666px;
            padding-bottom: 50px;
            position: relative;
            .swiper-pagination {
              left: 50%;
              padding-top: 10px;
              transform: translateX(-50%);
            }
            img {
              width: 100%;
            }
          }
          .bottom {
            display: flex;
            .date {
              border-right: 2px solid #ccc;
              padding: 10px;
              .max {
                font-size: 30px;
                color: #5c6371;
              }
            }
            .news {
              cursor: pointer;
              padding-left: 30px;
              h5 {
                color: #5b6475;
                font-size: 16px;
                padding-bottom: 10px;
                .titleText {
                  color: #d6a482;
                  padding-right: 10px;
                  border-right: 2px solid #d6a482;
                }
              }
              .Text {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                height: 50px;
                font-weight: 700;
                color: #4a5f6d;
                line-height: 25px;
                font-size: 13px;
                width: 480px;
              }
            }
          }
        }
        .body-right {
          padding-left: 30px;
          .head {
            position: relative;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #c1c5c8;
            padding-bottom: 17px;
            .left {
              text-align: center;
              flex: 1;
              display: flex;
              li {
                cursor: pointer;
                margin-right: 20px;
                width: 76px;
                padding: 5px 10px;
                background-color: #acbfcd;
                &.active {
                  background-color: #4a5f6d;
                  color: #fff;
                }
              }
            }
            .right {
              position: absolute;
              right: 0;
              padding: 0 10px;
              line-height: 50px;
              font-weight: 700;
              color: #4a5f6d;

            }
          }

          .bot {
            padding-top: 35px;
            li {
              display: flex;
              height: 130px;
              cursor: pointer;
              &:last-child {
                .right {
                  height: 80px;
                }
              }
              .left {
                width: 120px;
                margin-right: 22px;
                color: #5c6371;
                // padding: 8px 22px;
                .date {
                  font-size: 30px;
                  width: 100px;
                }
              }
              .right {
                // width: 525px;
                border-left: 2px solid #b6babc;
                padding-left: 50px;
                h5 {
                  font-size: 16px;
                  padding-bottom: 10px;
                  color: #5b6475;
                  .titleText {
                    color: #d6a482;
                    padding-right: 10px;
                    border-right: 2px solid #d6a482;
                  }
                }
                .Text {
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  height: 50px;
                  font-weight: 700;
                  color: #5b6475;
                  line-height: 25px;
                  font-size: 13px;
                  width: 480px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
