<template>
  <div class="bannerBox">
    <div class="bg">
      <img src="@/assets/change/newImg5.jpg" alt="">
    </div>
    <Head />
    <PlayVideo v-if="showMask" ref="PlayVideo" />
    <div class="banner">
      <div class="reserveBox">
        <div class="playVideo">
          <div class="title">
            <img class="rotateImg" src="https://thcdn.gggamedownload.com/source/assets/s4.png" @click="openVideo">
            <div class="around">
              <img src="https://thcdn.gggamedownload.com/source/assets/bofang-2.png" @click="openVideo">
            </div>
          </div>
        </div>
        <div class="reserve" />
      </div>

      <div class="footer">
        <div class="footerBox">
          <ul>
            <!-- <li class="erweima">
              <img src="@/assets/change/newImg4.png">
            </li> -->
            <li class="img2">
              <div>
                <!-- <img @click="clickDownLoad(lostworld_ios)" src="@/assets/change/newImg2.png" alt=""> -->
                <img @click="clickDownLoad(lostworld_android, 'lostworld_android')" src="@/assets/change/newImg6.png" alt="">
                <!--<a target="_blank" :href="lostworld_ios"><img src="@/assets/change/newImg2.png"></a>-->
                <!--<a :href="lostworld_android"><img src="@/assets/change/newImg6.png"></a>-->
                <!-- <a :href="lostworld_pc"><img src="@/assets/change/PcMoni.png"></a> -->
              </div>
            </li>
            <!-- <li class="Top"> <a target="_blank" href="https://l.taptap.com/e9t5rgdc"><img src="@/assets/change/newImg3.png"></a> </li> -->
            <li class="R16"><img src="https://thcdn.gggamedownload.com/source/assets/R16.png"></li>
          </ul>
        </div>
      </div>
    </div>
    <ReserveModels ref="ReserveModels" />

  </div>
</template>
<script>
import { reqGetLinks } from "@/api/news.js"
import { getLinksParams } from "@/utils/getDownloadLinks.js"
import Head from '@/components/Head'
import PlayVideo from '@/components/PlayVideo'
import ReserveModels from '@/components/ReserveModels'

export default {
  name: 'Banner',
  components: { Head, PlayVideo, ReserveModels },
  data() {
    return {
      showMask: false,
      lostworld_ios: "",
      lostworld_android: "",
      lostworld_pc: ""
    }
  },
  created() {
    this.getDownloadLinks()
  },
  methods: {
    clickDownLoad(url, type) {
      const link = document.createElement('a');
      if (type === 'lostworld_android') {
        const ourkey = 'RILvS9HYMOsiFptQ75dgW38V6Bh2Jyw';
        const time = Date.parse(new Date()) / 1000;
        const uri = url.split("com")[1]; // 取com后面的所有值
        const key = this.$md5(`${uri}${ourkey}${time}`); // 转 md5 密钥计算参数

        link.href = url + `?key=${key}&time=${time}`; // 设置下载链接
      } else {
        link.href = url; // 设置下载链接
      }
      link.download = url.substring(url.lastIndexOf("/") + 1); // 设置下载文件的名称 取最后一个/后面的内容为下载名字
      // 将 <a> 标签添加到 DOM 中
      document.body.appendChild(link);
      // 触发点击事件
      link.click();
      // 删除添加的 <a> 标签
      document.body.removeChild(link);
    },
    openVideo() {
      this.showMask = true
    },
    open() {
      this.$refs['ReserveModels'].showGame = true
    },
    async getDownloadLinks() {
      const res = await reqGetLinks(getLinksParams)

      res.data.forEach(item => {
        this[item.keywords] = item.data
      })
    }
  }

}
</script>

 <style lang='scss' scoped>

@-webkit-keyframes changeright {
  from {
    -webkit-transform: rotate(0deg)
  }

  to {
    -webkit-transform: rotate(360deg)
  }
}

@-moz-keyframes changeright {
  from {
    -moz-transform: rotate(0deg)
  }

  to {
    -moz-transform: rotate(360deg)
  }
}

@keyframes changeright {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}

   .bannerBox {
      width: 100%;
      // height: 950px;
      background-size: 100%;
      position: relative;
      .bg {
        position: absolute;
        z-index: 0;
        top: 0;
        img {
          width: 100%;
        }
      }

  }
  .banner {
    position: relative;
   height: 871px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 20px;
  .reserveBox {
    width: 100%;
    height: 420px;
    // border: 1px solid skyblue;
    .playVideo {

      display: flex;
      justify-content: center;
      align-items: center;
        text-align: center;
      .title {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        position: relative;
        .rotateImg {

          width: 10%;
          // -webkit-animation: changeright 6s linear infinite;
            animation: changeright 5s linear infinite;
        }
        .around {
          width: 100%;
          position: absolute;
          display: flex;
          justify-content: center;
          text-align: center;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          text-align: center;
          img {
            width: 7%;
            display: flex;
            justify-content: center;
            align-items: center;
           cursor: pointer;

          }
        }
      }
    }
  }
    .reserve {
    text-align: center;
    width: 100%;
    img {
      width: 22%;
    }

}

   .footer {
      position: absolute;
      width: 100%;
      height: 175px;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      .footerBox {

        ul {
          display: flex;
          justify-content: space-around;
         .erweima {

           width: 161px;
           display: flex;
           justify-content: center;
           align-items: center;
           img {
             width: 100%;

           }
         }
            .img2 {
              display: flex;
              justify-content: center;
              align-items: center;
              div {
                display: flex;
                flex-direction: column;
                margin: 0 15px;
                height: 148px;
                justify-content: space-evenly;
                img {
                  width: 148px;
                  // border: 10px solid transparent;
                  border-top: none;
                  cursor: pointer;
                }
              }
            }
            .Top {
              width: 147px;
              display: flex;
                  justify-content: center;
           align-items: center;
              img {
                width: 100%;
              }
            }
            .R16 {
              width: 134px;
              img {
                width: 100%;
              }
            }
        }
      }
    }

  }
 </style>

