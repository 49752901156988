<template>
  <div id="shipin" class="VisualFeast">
    <PlayVideo v-if="showMask" ref="PlayVideo" :video-src="videoPath" />
    <div class="header">
      <div class="title">
        <img src="https://thcdn.gggamedownload.com/source/assets/T-3.png">
      </div>
    </div>
    <div class="VisualFeast-warp">
      <div class="warp-body">
        <div class="videoLsit">
          <ul>
            <li v-for="(item, index) in videoList" :key="item.id" @click="openVideo(item)">
              <div v-if="index === 0" class="first" />
              <div v-if="index === 0" class="first2" />
              <img :src="item.img">

            </li>
            <!-- <li><img src="https://thcdn.gggamedownload.com/source/assets/shiting.jpg"></li> -->
          </ul>
        </div>
        <div class="warp-footer" @click="gotoVideo">
          更多内容
        </div>
      </div>

    </div>
    <div v-if="showImg" class="maskImg">
      <img src="https://thcdn.gggamedownload.com/source/assets/shitingshengyan/girl-m.jpg" alt="" @click="closeImg">
    </div>
  </div>
</template>
<script>
import PlayVideo from '@/components/PlayVideo'
import { reqGetVideoList } from '@/api/video'

export default {
  name: 'VisualFeast',
  components: { PlayVideo },
  data() {
    return {
      showMask: false,
      showImg: false,
      videoPath: '',
      queryForm: {
        channel: 'th.gg.com',
        pageSize: 2,
        currentPage: 1
      },
      videoList: [
        {
          id: 1,
          title: 'MV-Project第1弹—《指尖的热度》',
          author: '',
          img: 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/1-MV-2.jpg',
          address: 'https://thcdn.gggamedownload.com/source/MV/1-MV-2.mp4'
        },
        {
          id: 2,
          title: 'MV-Project第2弹《斑驳的木春菊》',
          author: '',
          img: 'https://thcdn.gggamedownload.com/source/assets/shitingshengyan/girl-m.jpg',
          address: 'https://thcdn.gggamedownload.com/source/MV/2-MV-1.mp4'
        }
      ]
    }
  },
  created() {
    this.getVideoList()
  },
  methods: {
    openVideo({ address, id }) {
      if (id === 1) {
        this.showMask = true
        this.videoPath = address
      }
      if (id === 2) {
        this.showImg = true
        // this.videoPath = address
      }
    },
    closeImg() {
      this.showImg = false
    },
    async getVideoList() {
      // const res = await reqGetVideoList(this.queryForm)
      // this.videoList = res.data
      // console.log(res)
    },
    gotoVideo() {
      this.$router.push({
        path: '/soundtrack',
        query: {
          type: 'video'
        }
      })
    }
  }

}
</script>
 <style lang='scss' scoped>
 .VisualFeast {

   .header {
      height: 270px;
      width: 100%;
      height: 263px;
      background: url('https://thcdn.gggamedownload.com/source/assets/zhuyeBG/BG-3.jpg') no-repeat center;
      display: flex;
      align-items: center;
     .title {
        width: 71%;
        margin: 0 auto;
        img {
          width: 364px;
        }
     }
   }
   .VisualFeast-warp {
     position: relative;
      width: 100%;
      height: 730px;
      background: url('https://thcdn.gggamedownload.com/source/assets/zhuyeBG/Efuda-(71).jpg') no-repeat center;
      background-size: 100%;
     .warp-body {
       width: 100%;
       height: 600px;
       display: flex;
       justify-content: center;
       align-items: center;
       .videoLsit {
        width: 71%;
         ul {
            display: flex;
            width: 100%;
            li {
              cursor: pointer;
              &:nth-child(1) {
                position: relative;
                margin-right: 28px;
              }
                .first {
                  content: '';
                  border: 1px solid #daba85;
                  width: 610px;
                  height: 343px;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translateX(-50%) translateY(-50%);
                  z-index: 1;
                  &::after {
                    position: absolute;
                    width: 106px;
                    height: 106px;
                    left: 50%;
                  top: 50%;
                  transform: translateX(-50%) translateY(-50%);
                    content: '';
                    background: url('https://thcdn.gggamedownload.com/source/assets/bofang.png') no-repeat center;

                  }
                }
                .first2 {
                    position: absolute;

                  width: 100%;
                  height: 100%;
                                background-color: rgba(0, 0, 0, 0.5);

                }
              img {
                box-sizing: content-box;
                border: 21px solid rgba(0,0,0,.6);
                width: 631px;
              }
            }
         }
       }
     }
     .warp-footer {
       position: absolute;
       bottom: 0;
       left: 0;
       width: 100%;
       height: 130px;
       line-height: 130px;
       background-color: rgba(0,0,0,.5);
       color: #788fa1;
       font-size: 85px;
       text-align: center;
       letter-spacing: 20px;
       cursor: pointer;
     }
   }
   .maskImg {
     position: fixed;
     left: 0;
     top: 0;
     width: 100vw;
     height: 100vh;
     z-index: 999;
     cursor: pointer;
     img {
       width: 100%;
     }
   }
 }
 </style>
