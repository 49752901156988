<template>
  <div class="DoujinStand">
    <div class="box">
      <div class="header">
        <div class="title">
          DoujinStand
        </div>
      </div>
      <div class="warp">
        <div class="warp-left">a</div>
        <div class="warp-right">b</div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: 'DoujinStand'

}
</script>
 <style lang='scss' scoped>
 .DoujinStand {
   width: 100%;
   .box {
     width: 71%;
     margin: 0 auto;
   .header {
     height: 200px;
   }
   .warp {
     display: flex;
   }
   }
 }
 </style>
