<template>
  <div id="renwu" class="Figure">
    <div v-show="imgMask" ref="mask" class="mask" @click.self="imgMask = false">
      <div id="mask-box" class="mask-box">
        <img :src="maskObj.showImgPath">
      </div>
      <div class="ban">
        <div class="text">
          <div>
            <div class="titleT3xt">
              <span class="text">{{ maskObj.title }}</span>
              <span class="JP_text">{{ maskObj.JPtitle }}</span>
            </div>
            <ul>
              <li v-if="maskObj.race">
                <span>种族</span> <i>{{ maskObj.race }}</i>
              </li>
              <li v-if="maskObj.ability">
                <span>能力</span> <i>{{ maskObj.ability }}</i>
              </li>
              <li v-if="maskObj.alias">
                <span>别名</span> <i>{{ maskObj.alias }}</i>
              </li>
              <li v-if="maskObj.profession">
                <span>职业</span> <i>{{ maskObj.profession }}</i>
              </li>

              <li v-if="maskObj.risk">
                <span>危险度</span> <i>{{ maskObj.risk }}</i>
              </li>
              <!-- <li v-if="maskObj.risk">
                <span>危险度</span> <i>{{ maskObj.risk }}</i>
              </li> -->
              <li v-if="maskObj.friendly">
                <span>人类友好度</span> <i>{{ maskObj.friendly }}</i>
              </li>
              <li v-if="maskObj.location">
                <span>主要活动地点</span> <i>{{ maskObj.location }}</i>
              </li>
              <li v-if="maskObj.residence">
                <span>住所</span> <i>{{ maskObj.residence }}</i>
              </li>
            </ul>
          </div>

          <div class="fenye">
            <i class="count">{{ maskObj.index +1 }}</i><span>/</span><i class="count2">09</i>
          </div>
          <div class="next">
            <span @click="handlePrevious"><img src="https://thcdn.gggamedownload.com/source/assets/cos/132.png"></span>
            <span @click="handleNext"><img src="https://thcdn.gggamedownload.com/source/assets/cos/133.png"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="FigureBody">
      <div class="Figure-warp">
        <h2 class="header">
          <div class="img">
            <img src="https://thcdn.gggamedownload.com/source/assets/T-2.png">
          </div>
        </h2>
        <div class="warp-body">
          <ul @mouseleave="showMask = ''">
            <li v-for="(item, index) in assetsList" :key="item.id" @click="handleImgClick(item, index)" @mouseover="handleHover(item)">
              <img v-if="showMask === item.id" :src="item.path">
              <img v-else :src="item.maskPath">
              <!-- <span v-show="showMask !== item.id" class="showMask" @keyup="handleEscClose">
                <p class="maskTitle">{{ item.title }} </p>
              </span> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const rw1 = 'https://thcdn.gggamedownload.com/source/assets/rw1-2.png'
const rw2 = 'https://thcdn.gggamedownload.com/source/assets/rw2-2.png'
const rw3 = 'https://thcdn.gggamedownload.com/source/assets/rw3-2.png'
const rw4 = 'https://thcdn.gggamedownload.com/source/assets/rw4-2.png'
const rw5 = 'https://thcdn.gggamedownload.com/source/assets/rw5-2.png'
const rw6 = 'https://thcdn.gggamedownload.com/source/assets/rw6-2.png'
const rw7 = 'https://thcdn.gggamedownload.com/source/assets/rw7-2.png'
const rw8 = 'https://thcdn.gggamedownload.com/source/assets/rw8-2.png'
const rw9 = 'https://thcdn.gggamedownload.com/source/assets/rw9-2.png'
const maskRw1 = 'https://thcdn.gggamedownload.com/source/assets/rw1-1.png'
const maskRw2 = 'https://thcdn.gggamedownload.com/source/assets/re2-1.png'
const maskRw3 = require('@/assets/lei.png')
const maskRw4 = 'https://thcdn.gggamedownload.com/source/assets/rw4-1.png'
const maskRw5 = 'https://thcdn.gggamedownload.com/source/assets/rw5-1.png'
const maskRw6 = 'https://thcdn.gggamedownload.com/source/assets/rw6-1.png'
const maskRw7 = 'https://thcdn.gggamedownload.com/source/assets/rw7-1.png'
const maskRw8 = 'https://thcdn.gggamedownload.com/source/assets/rw8-1.png'
const maskRw9 = 'https://thcdn.gggamedownload.com/source/assets/rw9-1.png'
const DetailsImg1 = 'https://thcdn.gggamedownload.com/source/assets/cos/14.png'
const DetailsImg2 = 'https://thcdn.gggamedownload.com/source/assets/cos/18.png'
const DetailsImg3 = 'https://thcdn.gggamedownload.com/source/assets/cos/17.png'
const DetailsImg4 = 'https://thcdn.gggamedownload.com/source/assets/cos/11.png'
const DetailsImg5 = 'https://thcdn.gggamedownload.com/source/assets/cos/12.png'
const DetailsImg6 = 'https://thcdn.gggamedownload.com/source/assets/cos/13.png'
const DetailsImg7 = 'https://thcdn.gggamedownload.com/source/assets/cos/19.png'
const DetailsImg8 = 'https://thcdn.gggamedownload.com/source/assets/cos/15.png'
const DetailsImg9 = 'https://thcdn.gggamedownload.com/source/assets/cos/16.png'
export default {
  name: 'Figure',
  data() {
    return {

      assetsList: [
        {
          id: 1,
          path: rw1,
          showMask: true,
          maskPath: maskRw1,
          showImgPath: DetailsImg1,
          race: '半人半灵',
          ability: '使用剑术程度的能力',
          alias: '“幽人的园艺师”、“生命的二刀流”、“半人半灵的半吊子”',
          profession: '',
          residence: '',
          title: '魂魄妖梦',
          JPtitle: 'こんぱく ようむ',
          risk: '中',
          friendly: '高',
          location: '冥界'
        },
        {
          id: 2,
          path: rw2,
          showMask: true,
          maskPath: maskRw2,
          showImgPath: DetailsImg2,
          race: '魔法使',
          ability: '操纵火＋水＋木＋金＋土＋日＋月的程度的能力',
          alias: '“知识与避世的少女”、“不动的大图书馆”、“不明的魔法之元”',
          profession: '',
          residence: '',
          title: '帕秋莉·诺蕾姬',
          JPtitle: 'パチュリー・ノーレッジ',
          risk: '中',
          friendly: '普通',
          location: '红魔馆'
        },
        {
          id: 3,
          path: rw3,
          showMask: true,
          maskPath: maskRw3,
          showImgPath: DetailsImg3,
          race: '吸血鬼',
          ability: '操纵命运程度的能力',
          alias: '“永远鲜红的幼月”、“红色恶魔”、“深红的夜行性恶魔”',
          profession: '',
          residence: '',
          title: '蕾米莉亚·斯卡雷特',
          JPtitle: 'レミリア・スカーレット',
          risk: '极高',
          friendly: '极低',
          location: '红魔馆附近'

        },
        {
          id: 4,
          path: rw4,
          showMask: true,
          maskPath: maskRw4,
          showImgPath: DetailsImg4,
          race: '人类',
          ability: '在空中飞行程度的能力',
          alias: '“博丽神社的巫女小姐”、“永远的巫女”、 ”乐园的可爱巫女“',
          profession: '巫女',
          residence: '博丽神社',
          title: '博丽灵梦',
          JPtitle: 'はくれい れいむ',
          risk: '',
          friendly: '',
          location: ''

        },
        {
          id: 5,
          path: rw5,
          showMask: true,
          maskPath: maskRw5,
          showImgPath: DetailsImg5,
          race: '人类',
          ability: '能够使用魔法程度的能力',
          alias: '“东洋的西洋魔法师”、“奇妙的魔法使”、“普通的黑魔法少女”',
          profession: '魔法使',
          residence: '魔法森林',
          title: '雾雨魔理沙',
          JPtitle: 'きりさめ まりさ',
          risk: '',
          friendly: '',
          location: ''

        },
        {
          id: 6,
          path: rw6,
          showMask: true,
          maskPath: maskRw6,
          showImgPath: DetailsImg6,
          race: '妖怪',
          ability: '操纵境界程度的能力',
          alias: '“神隐的主犯”、“境界的妖怪”、“潜藏于境界边的妖怪”',
          profession: '',
          residence: '',
          title: '八云紫',
          JPtitle: 'やくも ゆかり',
          risk: '不明',
          friendly: '普通',
          location: '任何地点'

        },
        {
          id: 7,
          path: rw7,
          showMask: true,
          maskPath: maskRw7,
          showImgPath: DetailsImg7,
          race: '人类',
          ability: '操纵时间程度的能力',
          alias: '“红魔馆的女仆”、“完美潇洒的从者”、“危险的魔术师”',
          profession: '女仆',
          residence: '红魔馆',
          title: '十六夜咲夜',
          JPtitle: 'いざよい さくや',
          risk: '',
          friendly: '',
          location: ''

        },
        {
          id: 8,
          path: rw8,
          showMask: true,
          maskPath: maskRw8,
          showImgPath: DetailsImg8,
          race: '魔法师',
          ability: '操作人偶程度的能力',
          alias: '“七色的人偶师”、“操纵人偶的魔法使”',
          profession: '',
          residence: '',
          title: '爱丽丝·玛格特洛依德',
          JPtitle: 'アリス・マーガトロイド',
          risk: '低',
          friendly: '高',
          location: '任何地点'

        },
        {
          id: 9,
          path: rw9,
          showMask: true,
          maskPath: maskRw9,
          showImgPath: DetailsImg9,
          race: '月兔',
          ability: '操纵狂气程度的能力',
          alias: '“狂气的月兔”、“令视界摇晃的妖怪兔”、“地上的月兔”',
          profession: '',
          residence: '',
          title: '铃仙·优昙华院·因幡',
          JPtitle: 'れいせん・うどんげいん・イナバ',
          risk: '不明',
          friendly: '普通',
          location: '迷途竹林'

        }

      ],
      maskObj: {

      },
      showMask: '',
      imgMask: false,
      active_text: null
    }
  },
  mounted() {
    // 添加键盘Esc事件
    this.$nextTick(() => {
      document.addEventListener('keyup', (e) => {
        if (e.code === 'Escape') {
          this.imgMask = false
        }
      })
    })
    this.$refs['mask'].style.height = document.getElementById('nav').clientHeight + 'px'
    if (this.$route.params.type === 'renwu') {
      this.getDOM('renwu')
    }
  },
  methods: {
    handleHover(item) {
      this.showMask = item.id
    },
    handleImgClick(item, index) {
      this.imgMask = true
      this.maskObj = item
      this.maskObj.index = index
      // this.active_text = item.id
      this.getDOM('renwu')
      // this.maskImgSrc = item.showImgPath
      this.$refs['mask'].style.height = document.getElementById('nav').clientHeight + 'px'
    },
    getDOM(id) {
      const DOM = document.getElementById(id)
      if (!DOM) return
      const top = DOM.offsetTop;
      document.documentElement.scrollTop = top;
      document.body.scrollTop = top;
    },
    handleEscClose(e) {
    },
    handlePrevious() {
      const index = this.maskObj.index
      if (index == 0) return
      this.maskObj = this.assetsList[index - 1]
      this.maskObj.index = index - 1
    },
    handleNext(e) {
      const index = this.maskObj.index
      if (index == 8) return
      this.maskObj = this.assetsList[index + 1]
      this.maskObj.index = index + 1
    }
  }

}
</script>
 <style lang='scss' scoped>
 .Figure {
   width: 100%;
   height: 100%;
  .mask {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0,.9);
    z-index: 99999;
    .mask-box {
      color: #fff;
      z-index: 10;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      img {
        width: 815px;
      }
    }
    .ban {
      height: 356px;
      width: 100%;
      background: url('https://thcdn.gggamedownload.com/source/assets/cos/ban.jpg') no-repeat center;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-size: 100%;
      display: flex;
      justify-content: flex-end;
      .text {
        position: relative;
        height: 100%;
        width: 52%;
        padding-top: 53px;

        .titleT3xt {
          // width: 500px;
          position: absolute;
          top: 0;
          left: 0;
          transform: translateY(-100%);
          .text {
            font-size: 70px;
            color: #e1c28f;
          }
          .JP_text {
            color: #fff1d9;
            font-size: 20px;
          }
        }
        li {
         span {
           display: inline-block;
           margin-top: 7px;
           margin-right: 15px;
           background-color: #811e22;
           color: #fff;
           padding: 0 5px;
         }
         i {
           font-style: normal;
           display: inline-block;
          //  width: 350px;
           color: #000;
           font-weight: 700;
           font-size: 18px;
         }
        }
        .fenye {
          position: absolute;
          bottom: 0;
          left: 200px;
          color: #8a909b;
          bottom: 40px;
          span {
            display: inline-block;
            height: 100%;
            font-size: 24px;
            line-height: 10px;
          }
          .count {
            font-size: 48px;
          }
          .count2 {
            font-size: 30px;
          }
        }

        .next {
          display: flex;
          justify-content:space-between;
          width: 83px;
          height: 36px;
          position: absolute;
          bottom: 40px;
          left: 60%;
          img {
            width: 36px;
          }
        }
      }
    }
  }
   .FigureBody {
     width: 100%;
     .Figure-warp {
      .header {
        margin: 0 auto;
        height: 263px;
        background: url('https://thcdn.gggamedownload.com/source/assets/zhuyeBG/BG-3.jpg') no-repeat center;
        display: flex;
        justify-content: center;
        align-items: center;
        .img {
          width: 71%;
          img {
            width: 364px;
          }
        }
      }
      .warp-body {
        width: 100%;
        ul {
          display: flex;
          justify-content: space-around;
          background-color: rgba(0, 0, 0,.8);

          li {
            // background-color: skyblue;
            position: relative;
            cursor: pointer;
            img {
              width: 100%;
              // border-right: 4px solid #000;
            }
            .showMask {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              // border-right: 4px solid #000;
              background-color: rgba(66, 71, 86,.8);
              z-index: 1;

              .maskTitle {
                writing-mode:vertical-lr;
              color: #fff;
              letter-spacing: 1em;
              font-family: 'SourceHanSerifCN-SemiBold';
              }
            }
          }
        }
      }
     }
   }
 }
 </style>
